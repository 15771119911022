import { Link } from 'gatsby'
import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import AdminLayout from '../../../components/layouts/admin'
import { getBlogPosts } from '../../../utils/requests'
import {
  BLACK_COLOR_RGB,
  PRIMARY_COLOR,
  WHITE_COLOR,
} from '../../../components/common/color'

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 200px;
  padding: 3rem 15% 0px 15%;
  height:100vh;
  overflow-y:auto;
`
const MenuList = styled.ul`
  list-style: none;
  margin: 1rem 0;
  padding: 0;
  border: 1px solid ${BLACK_COLOR_RGB(0.2)};
  border-radius: 5px;
  grid-column: 1/3;
  margin-bottom: 100px;
`

const MenuItem = styled.li`
  border-top: 1px solid ${BLACK_COLOR_RGB(0.2)};

  &:first-of-type {
    border-top: 0;
  }
`

const MenuItemLink = styled(Link)`
  display: inline-block;
  padding: 1rem 2rem;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    background-color: ${BLACK_COLOR_RGB(0.1)};
  }
`

const AddNewButton = styled(Link)`
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: ${PRIMARY_COLOR};
  color: ${WHITE_COLOR};
  text-transform: uppercase;
  border: 0;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
`

const Title = styled.h1`
  grid-column: 1/2;
`

const ActionButton = styled.div`
  grid-column: 2/3;
  display: grid;
  align-content: center;
  justify-content: end;
`

const BlogIndexPage = () => {
  const [state, setState] = useState([])

  useEffect(() => {
    const fetchBlogs = async () => {
      const { data: blogs } = await getBlogPosts('blog', true)

      blogs.sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime()
      })

      setState(blogs)
    }
    fetchBlogs()
  }, [])

  return (
    <AdminLayout>
      <Container>
        <Title>Posts</Title>
        <ActionButton>
          <AddNewButton to="/admin/blog/new">Create</AddNewButton>
        </ActionButton>
        <MenuList>
          {state.map((post) => (
            <MenuItem key={post.id}>
              <MenuItemLink to={`/admin/blog/${post.id}`}>
                {post.title}
              </MenuItemLink>
            </MenuItem>
          ))}
        </MenuList>
      </Container>
    </AdminLayout>
  )
}

export default BlogIndexPage
